<template>
  <div>
    <digos-coop-sulop-ruparan v-if="branch_id===2"></digos-coop-sulop-ruparan>
  </div>
</template>

<script>
  import DigosCoopSulopRuparan from './digos_coop/SulopRuparan'
  import {mapGetters} from "vuex";

  export default {
    components: {
      DigosCoopSulopRuparan,
    },
    computed: {
      ...mapGetters('authentication', ['branch_id'])
    },
  }
</script>
