<template>
  <v-layout column wrap>
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-layout row wrap class="align-center">
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_1"
                      height="100"
                      @click="check_details(block_1_lot_7,block_1_lot_7_name)">
                <v-toolbar dense dark :color="block_1_lot_7">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 1</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 7</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2"
                      height="100"
                      @click="check_details(block_2_lot_1,block_2_lot_1_name)">
                <v-toolbar dense dark :color="block_2_lot_1">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 1</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2"
                      height="100"
                      @click="check_details(block_2_lot_2,block_2_lot_2_name)">
                <v-toolbar dense dark :color="block_2_lot_2">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 2</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2"
                      height="100"
                      @click="check_details(block_2_lot_3,block_2_lot_3_name)">
                <v-toolbar dense dark :color="block_2_lot_3">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 3</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2"
                      height="100"
                      @click="check_details(block_2_lot_4,block_2_lot_4_name)">
                <v-toolbar dense dark :color="block_2_lot_4">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 4</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2"
                      height="100"
                      @click="check_details(block_2_lot_5,block_2_lot_5_name)">
                <v-toolbar dense dark :color="block_2_lot_5">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 5</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2_2"
                      height="100"
                      @click="check_details(block_2_lot_6,block_2_lot_6_name)">
                <v-toolbar dense dark :color="block_2_lot_6">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 6</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2_2"
                      height="100"
                      @click="check_details(block_2_lot_7,block_2_lot_7_name)">
                <v-toolbar dense dark :color="block_2_lot_7">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 7</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2_2"
                      height="100"
                      @click="check_details(block_2_lot_8,block_2_lot_8_name)">
                <v-toolbar dense dark :color="block_2_lot_8">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 8</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2_2"
                      height="100"
                      @click="check_details(block_2_lot_9,block_2_lot_9_name)">
                <v-toolbar dense dark :color="block_2_lot_9">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 9</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_2_2"
                      height="100"
                      @click="check_details(block_2_lot_10,block_2_lot_10_name)">
                <v-toolbar dense dark :color="block_2_lot_10">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 10</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1>
              <v-card :color="block_2_3"
                      height="100"
                      @click="check_details(block_2_lot_11,block_2_lot_11_name)">
                <v-toolbar dense dark :color="block_2_lot_11">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 11</v-card-subtitle>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="align-center mt-4">
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_1"
                      height="100"
                      @click="check_details(block_1_lot_6,block_1_lot_6_name)">
                <v-toolbar dense dark :color="block_1_lot_6">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 1</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 6</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_2,block_3_lot_2_name)">
                <v-toolbar dense dark :color="block_3_lot_2">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 2</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_3,block_3_lot_3_name)">
                <v-toolbar dense dark :color="block_3_lot_3">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 3</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_6,block_3_lot_6_name)">
                <v-toolbar dense dark :color="block_3_lot_6">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 6</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_7,block_3_lot_7_name)">
                <v-toolbar dense dark :color="block_3_lot_7">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 7</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_10,block_3_lot_10_name)">
                <v-toolbar dense dark :color="block_3_lot_10">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 10</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_11,block_3_lot_11_name)">
                <v-toolbar dense dark :color="block_3_lot_11">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 11</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs2 md2 class="pr-2">
              <v-card :color="block_6"
                      height="100"
                      @click="check_details(block_6_lot_6,block_6_lot_6_name)">
                <v-toolbar dense dark :color="block_6_lot_6">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 6</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 6</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs2 md2 class="pr-2">
              <v-card :color="block_6"
                      height="100"
                      @click="check_details(block_6_lot_7,block_6_lot_7_name)">
                <v-toolbar dense dark :color="block_6_lot_7">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 6</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 7</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1>
              <v-card :color="block_2_3"
                      height="100"
                      @click="check_details(block_2_lot_12,block_2_lot_12_name)">
                <v-toolbar dense dark :color="block_2_lot_12">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 12</v-card-subtitle>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="align-center mt-4">
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_1"
                      height="100"
                      @click="check_details(block_1_lot_5,block_1_lot_5_name)">
                <v-toolbar dense dark :color="block_1_lot_5">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 1</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 5</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_1,block_3_lot_1_name)">
                <v-toolbar dense dark :color="block_3_lot_1">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 1</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_4,block_3_lot_4_name)">
                <v-toolbar dense dark :color="block_3_lot_4">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 4</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_5,block_3_lot_5_name)">
                <v-toolbar dense dark :color="block_3_lot_5">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 5</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_8,block_3_lot_8_name)">
                <v-toolbar dense dark :color="block_3_lot_8">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 8</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_9,block_3_lot_9_name)">
                <v-toolbar dense dark :color="block_3_lot_9">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 9</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_3"
                      height="100"
                      @click="check_details(block_3_lot_12,block_3_lot_12_name)">
                <v-toolbar dense dark :color="block_3_lot_12">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 3</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 12</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs2 md2 class="pr-2">
              <v-card :color="block_6"
                      height="100"
                      @click="check_details(block_6_lot_5,block_6_lot_5_name)">
                <v-toolbar dense dark :color="block_6_lot_5">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 6</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 5</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs2 md2 class="pr-2">
              <v-card :color="block_6"
                      height="100"
                      @click="check_details(block_6_lot_4,block_6_lot_4_name)">
                <v-toolbar dense dark :color="block_6_lot_4">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 6</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 4</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1>
              <v-card :color="block_2_3"
                      height="100"
                      @click="check_details(block_2_lot_13,block_2_lot_13_name)">
                <v-toolbar dense dark :color="block_2_lot_13">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 13</v-card-subtitle>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="align-center mt-4">
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_1"
                      height="100"
                      @click="check_details(block_1_lot_4,block_1_lot_4_name)">
                <v-toolbar dense dark :color="block_1_lot_4">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 1</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 4</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_2,block_4_lot_2_name)">
                <v-toolbar dense dark :color="block_4_lot_2">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 2</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_3,block_4_lot_3_name)">
                <v-toolbar dense dark :color="block_4_lot_3">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 3</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_6,block_4_lot_6_name)">
                <v-toolbar dense dark :color="block_4_lot_6">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 6</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_7,block_4_lot_7_name)">
                <v-toolbar dense dark :color="block_4_lot_7">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 7</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_10,block_4_lot_10_name)">
                <v-toolbar dense dark :color="block_4_lot_10">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 10</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4_2"
                      height="100"
                      @click="check_details(block_4_lot_11,block_4_lot_11_name)">
                <v-toolbar dense dark :color="block_4_lot_11">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 11</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4_2"
                      height="100"
                      @click="check_details(block_4_lot_14,block_4_lot_14_name)">
                <v-toolbar dense dark :color="block_4_lot_14">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 14</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs3 md3 class="pr-2">
              <v-card :color="block_6"
                      height="100"
                      @click="check_details(block_6_lot_3,block_6_lot_3_name)">
                <v-toolbar dense dark :color="block_6_lot_3">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 6</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 3</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1>
              <v-card :color="block_2_3"
                      height="100"
                      @click="check_details(block_2_lot_14,block_2_lot_14_name)">
                <v-toolbar dense dark :color="block_2_lot_14">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 14</v-card-subtitle>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="align-center mt-4">
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_1"
                      height="100"
                      @click="check_details(block_1_lot_3,block_1_lot_3_name)">
                <v-toolbar dense dark :color="block_1_lot_3">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 1</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 3</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_1,block_4_lot_1_name)">
                <v-toolbar dense dark :color="block_4_lot_1">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 1</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_4,block_4_lot_4_name)">
                <v-toolbar dense dark :color="block_4_lot_4">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 4</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_5,block_4_lot_5_name)">
                <v-toolbar dense dark :color="block_4_lot_5">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 5</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_8,block_4_lot_8_name)">
                <v-toolbar dense dark :color="block_4_lot_8">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 8</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_9,block_4_lot_9_name)">
                <v-toolbar dense dark :color="block_4_lot_9">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 9</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4"
                      height="100"
                      @click="check_details(block_4_lot_12,block_4_lot_12_name)">
                <v-toolbar dense dark :color="block_4_lot_12">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 12</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4_2"
                      height="100"
                      @click="check_details(block_4_lot_13,block_4_lot_13_name)">
                <v-toolbar dense dark :color="block_4_lot_13">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 13</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_4_2"
                      height="100"
                      @click="check_details(block_4_lot_15,block_4_lot_15_name)">
                <v-toolbar dense dark :color="block_4_lot_15">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 4</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 15</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs2 md2 class="pr-2">
              <v-card :color="block_6"
                      height="100"
                      @click="check_details(block_6_lot_2,block_6_lot_2_name)">
                <v-toolbar dense dark :color="block_6_lot_2">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 6</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 2</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1>
              <v-card :color="block_2_3"
                      height="100"
                      @click="check_details(block_2_lot_15,block_2_lot_15_name)">
                <v-toolbar dense dark :color="block_2_lot_15">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 15</v-card-subtitle>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="align-center mt-4">
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_1"
                      height="100"
                      @click="check_details(block_1_lot_2,block_1_lot_2_name)">
                <v-toolbar dense dark :color="block_1_lot_2">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 1</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 2</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs2 md2 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_4,block_5_lot_4_name)">
                <v-toolbar dense dark :color="block_5_lot_4">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 4</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_6,block_5_lot_6_name)">
                <v-toolbar dense dark :color="block_5_lot_6">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 6</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_8,block_5_lot_8_name)">
                <v-toolbar dense dark :color="block_5_lot_8">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 8</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_10,block_5_lot_10_name)">
                <v-toolbar dense dark :color="block_5_lot_10">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 10</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_12,block_5_lot_12_name)">
                <v-toolbar dense dark :color="block_5_lot_12">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 12</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_13,block_5_lot_13_name)">
                <v-toolbar dense dark :color="block_5_lot_13">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 13</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5_2"
                      height="100"
                      @click="check_details(block_5_lot_16,block_5_lot_16_name)">
                <v-toolbar dense dark :color="block_5_lot_16">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 16</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs2 md2 class="pr-2">
              <v-card :color="block_6"
                      height="100"
                      @click="check_details(block_6_lot_1,block_6_lot_1_name)">
                <v-toolbar dense dark :color="block_6_lot_1">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 6</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 1</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1>
              <v-card :color="block_2_3"
                      height="100"
                      @click="check_details(block_2_lot_16,block_2_lot_16_name)">
                <v-toolbar dense dark :color="block_2_lot_16">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 2</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 16</v-card-subtitle>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="align-center mt-4">
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_1"
                      height="100"
                      @click="check_details(block_1_lot_1,block_1_lot_1_name)">
                <v-toolbar dense dark :color="block_1_lot_1">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 1</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 1</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_1,block_5_lot_1_name)">
                <v-toolbar dense dark :color="block_5_lot_1">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 1</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_2,block_5_lot_2_name)">
                <v-toolbar dense dark :color="block_5_lot_2">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 2</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_3,block_5_lot_3_name)">
                <v-toolbar dense dark :color="block_5_lot_3">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 3</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_5,block_5_lot_5_name)">
                <v-toolbar dense dark :color="block_5_lot_5">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 5</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_7,block_5_lot_7_name)">
                <v-toolbar dense dark :color="block_5_lot_7">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 7</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_9,block_5_lot_9_name)">
                <v-toolbar dense dark :color="block_5_lot_9">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 9</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5"
                      height="100"
                      @click="check_details(block_5_lot_11,block_5_lot_11_name)">
                <v-toolbar dense dark :color="block_5_lot_11">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 11</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5_2"
                      height="100"
                      @click="check_details(block_5_lot_14,block_5_lot_14_name)">
                <v-toolbar dense dark :color="block_5_lot_14">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 14</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5_2"
                      height="100"
                      @click="check_details(block_5_lot_15,block_5_lot_15_name)">
                <v-toolbar dense dark :color="block_5_lot_15">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 15</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1 class="pr-2">
              <v-card :color="block_5_2"
                      height="100"
                      @click="check_details(block_5_lot_17,block_5_lot_17_name)">
                <v-toolbar dense dark :color="block_5_lot_17">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 17</v-card-subtitle>
              </v-card>
            </v-flex>
            <v-flex xs1 md1>
              <v-card :color="block_5_2"
                      height="100"
                      @click="check_details(block_5_lot_18,block_5_lot_18_name)">
                <v-toolbar dense dark :color="block_5_lot_18">
                  <v-toolbar-title><p style="font-size: 11px">BLOCK 5</p>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle class="font-weight-bold">LOT 18</v-card-subtitle>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-dialog v-model="details_dialog" max-width="40%">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title>
          RAW LOTS INFORMATION
        </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-container>
            <h2 class="font-weight-regular">Name: {{ name }}</h2>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>

  import {mapActions} from "vuex";
  import {mapGetters} from 'vuex'

  export default {
    mounted() {
      this.retrieve_data()
    },
    data() {
      return {
        details_dialog: false,
        name: '',

        list_of_occupaid: [],
        block_1: '',

        block_2: '',
        block_2_2: '',
        block_2_3: '',

        block_3: '',

        block_4: '',
        block_4_2: '',

        block_5: '',
        block_5_2: '',

        block_6: '',

        block_1_lot_1: '',
        block_1_lot_2: '',
        block_1_lot_3: '',
        block_1_lot_4: '',
        block_1_lot_5: '',
        block_1_lot_6: '',
        block_1_lot_7: '',

        block_1_lot_1_name: '',
        block_1_lot_2_name: '',
        block_1_lot_3_name: '',
        block_1_lot_4_name: '',
        block_1_lot_5_name: '',
        block_1_lot_6_name: '',
        block_1_lot_7_name: '',

        block_2_lot_1: '',
        block_2_lot_2: '',
        block_2_lot_3: '',
        block_2_lot_4: '',
        block_2_lot_5: '',
        block_2_lot_6: '',
        block_2_lot_7: '',
        block_2_lot_8: '',
        block_2_lot_9: '',
        block_2_lot_10: '',
        block_2_lot_11: '',
        block_2_lot_12: '',
        block_2_lot_13: '',
        block_2_lot_14: '',
        block_2_lot_15: '',
        block_2_lot_16: '',

        block_2_lot_1_name: '',
        block_2_lot_2_name: '',
        block_2_lot_3_name: '',
        block_2_lot_4_name: '',
        block_2_lot_5_name: '',
        block_2_lot_6_name: '',
        block_2_lot_7_name: '',
        block_2_lot_8_name: '',
        block_2_lot_9_name: '',
        block_2_lot_10_name: '',
        block_2_lot_11_name: '',
        block_2_lot_12_name: '',
        block_2_lot_13_name: '',
        block_2_lot_14_name: '',
        block_2_lot_15_name: '',
        block_2_lot_16_name: '',

        block_3_lot_1: '',
        block_3_lot_2: '',
        block_3_lot_3: '',
        block_3_lot_4: '',
        block_3_lot_5: '',
        block_3_lot_6: '',
        block_3_lot_7: '',
        block_3_lot_8: '',
        block_3_lot_9: '',
        block_3_lot_10: '',
        block_3_lot_11: '',
        block_3_lot_12: '',

        block_3_lot_1_name: '',
        block_3_lot_2_name: '',
        block_3_lot_3_name: '',
        block_3_lot_4_name: '',
        block_3_lot_5_name: '',
        block_3_lot_6_name: '',
        block_3_lot_7_name: '',
        block_3_lot_8_name: '',
        block_3_lot_9_name: '',
        block_3_lot_10_name: '',
        block_3_lot_11_name: '',
        block_3_lot_12_name: '',

        block_4_lot_1: '',
        block_4_lot_2: '',
        block_4_lot_3: '',
        block_4_lot_4: '',
        block_4_lot_5: '',
        block_4_lot_6: '',
        block_4_lot_7: '',
        block_4_lot_8: '',
        block_4_lot_9: '',
        block_4_lot_10: '',
        block_4_lot_11: '',
        block_4_lot_12: '',
        block_4_lot_13: '',
        block_4_lot_14: '',
        block_4_lot_15: '',

        block_4_lot_1_name: '',
        block_4_lot_2_name: '',
        block_4_lot_3_name: '',
        block_4_lot_4_name: '',
        block_4_lot_5_name: '',
        block_4_lot_6_name: '',
        block_4_lot_7_name: '',
        block_4_lot_8_name: '',
        block_4_lot_9_name: '',
        block_4_lot_10_name: '',
        block_4_lot_11_name: '',
        block_4_lot_12_name: '',
        block_4_lot_13_name: '',
        block_4_lot_14_name: '',
        block_4_lot_15_name: '',

        block_5_lot_1: '',
        block_5_lot_2: '',
        block_5_lot_3: '',
        block_5_lot_4: '',
        block_5_lot_5: '',
        block_5_lot_6: '',
        block_5_lot_7: '',
        block_5_lot_8: '',
        block_5_lot_9: '',
        block_5_lot_10: '',
        block_5_lot_11: '',
        block_5_lot_12: '',
        block_5_lot_13: '',
        block_5_lot_14: '',
        block_5_lot_15: '',
        block_5_lot_16: '',
        block_5_lot_17: '',
        block_5_lot_18: '',

        block_5_lot_1_name: '',
        block_5_lot_2_name: '',
        block_5_lot_3_name: '',
        block_5_lot_4_name: '',
        block_5_lot_5_name: '',
        block_5_lot_6_name: '',
        block_5_lot_7_name: '',
        block_5_lot_8_name: '',
        block_5_lot_9_name: '',
        block_5_lot_10_name: '',
        block_5_lot_11_name: '',
        block_5_lot_12_name: '',
        block_5_lot_13_name: '',
        block_5_lot_14_name: '',
        block_5_lot_15_name: '',
        block_5_lot_16_name: '',
        block_5_lot_17_name: '',
        block_5_lot_18_name: '',

        block_6_lot_1: '',
        block_6_lot_2: '',
        block_6_lot_3: '',
        block_6_lot_4: '',
        block_6_lot_5: '',
        block_6_lot_6: '',
        block_6_lot_7: '',

        block_6_lot_1_name: '',
        block_6_lot_2_name: '',
        block_6_lot_3_name: '',
        block_6_lot_4_name: '',
        block_6_lot_5_name: '',
        block_6_lot_6_name: '',
        block_6_lot_7_name: '',
      }
    },
    props: {
      block: Number,
      color: String,
    },
    watch: {
      branch_id: function () {
        this.retrieve_data()
      }
    },
    computed: {
      ...mapGetters('authentication', ['branch_id'])
    },
    methods: {
      ...mapActions('raw_lots_location_data', ['get_raw_lots_occupaid']),
      check_details(is_used, member_name) {
        if (is_used === 'error') {
          this.details_dialog = true
          this.name = member_name
        }
      },
      retrieve_data() {
        if (this.block === 1) {
          this.block_1 = 'accent'
          this.block_1_lot_1 = 'info'
          this.block_1_lot_2 = 'info'
          this.block_1_lot_3 = 'info'
          this.block_1_lot_4 = 'info'
          this.block_1_lot_5 = 'info'
          this.block_1_lot_6 = 'info'
          this.block_1_lot_7 = 'info'
        }
        if (this.block === 2) {
          this.block_2 = 'light_green'
          this.block_2_2 = 'primary'
          this.block_2_3 = 'light_orange'

          this.block_2_lot_1 = 'warning'
          this.block_2_lot_2 = 'warning'
          this.block_2_lot_3 = 'warning'
          this.block_2_lot_4 = 'warning'
          this.block_2_lot_5 = 'warning'
          this.block_2_lot_6 = 'warning'
          this.block_2_lot_7 = 'warning'
          this.block_2_lot_8 = 'warning'
          this.block_2_lot_9 = 'warning'
          this.block_2_lot_10 = 'warning'
          this.block_2_lot_11 = 'warning'
          this.block_2_lot_12 = 'warning'
          this.block_2_lot_13 = 'warning'
          this.block_2_lot_14 = 'warning'
          this.block_2_lot_15 = 'warning'
          this.block_2_lot_16 = 'warning'
        }
        if (this.block === 3) {
          this.block_3 = 'light_green'

          this.block_3_lot_1 = 'darken_orange'
          this.block_3_lot_2 = 'darken_orange'
          this.block_3_lot_3 = 'darken_orange'
          this.block_3_lot_4 = 'darken_orange'
          this.block_3_lot_5 = 'darken_orange'
          this.block_3_lot_6 = 'darken_orange'
          this.block_3_lot_7 = 'darken_orange'
          this.block_3_lot_8 = 'darken_orange'
          this.block_3_lot_9 = 'darken_orange'
          this.block_3_lot_10 = 'darken_orange'
          this.block_3_lot_11 = 'darken_orange'
          this.block_3_lot_12 = 'darken_orange'
        }
        if (this.block === 4) {
          this.block_4 = 'light_green'
          this.block_4_2 = 'primary'
          this.block_4_lot_1 = 'darken_green'
          this.block_4_lot_2 = 'darken_green'
          this.block_4_lot_3 = 'darken_green'
          this.block_4_lot_4 = 'darken_green'
          this.block_4_lot_5 = 'darken_green'
          this.block_4_lot_6 = 'darken_green'
          this.block_4_lot_7 = 'darken_green'
          this.block_4_lot_8 = 'darken_green'
          this.block_4_lot_9 = 'darken_green'
          this.block_4_lot_10 = 'darken_green'
          this.block_4_lot_11 = 'darken_green'
          this.block_4_lot_12 = 'darken_green'
          this.block_4_lot_13 = 'darken_green'
          this.block_4_lot_14 = 'darken_green'
          this.block_4_lot_15 = 'darken_green'
        }
        if (this.block === 5) {
          this.block_5 = 'light_green'
          this.block_5_2 = 'primary'

          this.block_5_lot_1 = 'black'
          this.block_5_lot_2 = 'black'
          this.block_5_lot_3 = 'black'
          this.block_5_lot_4 = 'black'
          this.block_5_lot_5 = 'black'
          this.block_5_lot_6 = 'black'
          this.block_5_lot_7 = 'black'
          this.block_5_lot_8 = 'black'
          this.block_5_lot_9 = 'black'
          this.block_5_lot_10 = 'black'
          this.block_5_lot_11 = 'black'
          this.block_5_lot_12 = 'black'
          this.block_5_lot_13 = 'black'
          this.block_5_lot_14 = 'black'
          this.block_5_lot_15 = 'black'
          this.block_5_lot_16 = 'black'
          this.block_5_lot_17 = 'black'
          this.block_5_lot_18 = 'black'
        }
        if (this.block === 6) {
          this.block_6 = 'primary'

          this.block_6_lot_1 = 'darken_brown'
          this.block_6_lot_2 = 'darken_brown'
          this.block_6_lot_3 = 'darken_brown'
          this.block_6_lot_4 = 'darken_brown'
          this.block_6_lot_5 = 'darken_brown'
          this.block_6_lot_6 = 'darken_brown'
          this.block_6_lot_7 = 'darken_brown'
        }
        this.get_raw_lots_occupaid({
          branch_id: this.branch_id,
        })
          .then(response => {
            for (var x = 0; x < response.data.length; x++) {
              var item = response.data[x]
              var name = item.members_information.last_name + ', ' + item.members_information.first_name + ' ' + item.members_information.middle_name
              switch (item.block_no) {
                case 1:
                  switch (item.lot_no) {
                    case 1:
                      this.block_1_lot_1 = 'error'
                      this.block_1_lot_1_name = name
                      break
                    case 2:
                      this.block_1_lot_2 = 'error'
                      this.block_1_lot_2_name = name
                      break
                    case 3:
                      this.block_1_lot_3 = 'error'
                      this.block_1_lot_3_name = name
                      break
                    case 4:
                      this.block_1_lot_4 = 'error'
                      this.block_1_lot_4_name = name
                      break
                    case 5:
                      this.block_1_lot_5 = 'error'
                      this.block_1_lot_5_name = name
                      break
                    case 6:
                      this.block_1_lot_6 = 'error'
                      this.block_1_lot_6_name = name
                      break
                    case 7:
                      this.block_1_lot_7 = 'error'
                      this.block_1_lot_7_name = name
                      break
                  }
                  break
                case 2:
                  switch (item.lot_no) {
                    case 1:
                      this.block_2_lot_1 = 'error'
                      this.block_2_lot_1_name = name
                      break
                    case 2:
                      this.block_2_lot_2 = 'error'
                      this.block_2_lot_2_name = name
                      break
                    case 3:
                      this.block_2_lot_3 = 'error'
                      this.block_2_lot_3_name = name
                      break
                    case 4:
                      this.block_2_lot_4 = 'error'
                      this.block_2_lot_4_name = name
                      break
                    case 5:
                      this.block_2_lot_5 = 'error'
                      this.block_2_lot_5_name = name
                      break
                    case 6:
                      this.block_2_lot_6 = 'error'
                      this.block_2_lot_6_name = name
                      break
                    case 7:
                      this.block_2_lot_7 = 'error'
                      this.block_2_lot_7_name = name
                      break
                    case 8:
                      this.block_2_lot_8 = 'error'
                      this.block_2_lot_8_name = name
                      break
                    case 9:
                      this.block_2_lot_9 = 'error'
                      this.block_2_lot_9_name = name
                      break
                    case 10:
                      this.block_2_lot_10 = 'error'
                      this.block_2_lot_10_name = name
                      break
                    case 11:
                      this.block_2_lot_11 = 'error'
                      this.block_2_lot_11_name = name
                      break
                    case 12:
                      this.block_2_lot_12 = 'error'
                      this.block_2_lot_12_name = name
                      break
                    case 13:
                      this.block_2_lot_13 = 'error'
                      this.block_2_lot_13_name = name
                      break
                    case 14:
                      this.block_2_lot_14 = 'error'
                      this.block_2_lot_14_name = name
                      break
                    case 15:
                      this.block_2_lot_15 = 'error'
                      this.block_2_lot_15_name = name
                      break
                    case 16:
                      this.block_2_lot_16 = 'error'
                      this.block_2_lot_16_name = name
                      break
                  }
                  break
                case 3:
                  switch (item.lot_no) {
                    case 1:
                      this.block_3_lot_1 = 'error'
                      this.block_3_lot_1_name = name
                      break
                    case 2:
                      this.block_3_lot_2 = 'error'
                      this.block_3_lot_2_name = name
                      break
                    case 3:
                      this.block_3_lot_3 = 'error'
                      this.block_3_lot_3_name = name
                      break
                    case 4:
                      this.block_3_lot_4 = 'error'
                      this.block_3_lot_4_name = name
                      break
                    case 5:
                      this.block_3_lot_5 = 'error'
                      this.block_3_lot_5_name = name
                      break
                    case 6:
                      this.block_3_lot_6 = 'error'
                      this.block_3_lot_6_name = name
                      break
                    case 7:
                      this.block_3_lot_7 = 'error'
                      this.block_3_lot_7_name = name
                      break
                    case 8:
                      this.block_3_lot_8 = 'error'
                      this.block_3_lot_8_name = name
                      break
                    case 9:
                      this.block_3_lot_9 = 'error'
                      this.block_3_lot_9_name = name
                      break
                    case 10:
                      this.block_3_lot_10 = 'error'
                      this.block_3_lot_10_name = name
                      break
                    case 11:
                      this.block_3_lot_11 = 'error'
                      this.block_3_lot_11_name = name
                      break
                    case 12:
                      this.block_3_lot_12 = 'error'
                      this.block_3_lot_12_name = name
                      break
                  }
                  break
                case 4:
                  switch (item.lot_no) {
                    case 1:
                      this.block_4_lot_1 = 'error'
                      this.block_4_lot_1_name = name
                      break
                    case 2:
                      this.block_4_lot_2 = 'error'
                      this.block_4_lot_2_name = name
                      break
                    case 3:
                      this.block_4_lot_3 = 'error'
                      this.block_4_lot_3_name = name
                      break
                    case 4:
                      this.block_4_lot_4 = 'error'
                      this.block_4_lot_4_name = name
                      break
                    case 5:
                      this.block_4_lot_5 = 'error'
                      this.block_4_lot_5_name = name
                      break
                    case 6:
                      this.block_4_lot_6 = 'error'
                      this.block_4_lot_6_name = name
                      break
                    case 7:
                      this.block_4_lot_7 = 'error'
                      this.block_4_lot_7_name = name
                      break
                    case 8:
                      this.block_4_lot_8 = 'error'
                      this.block_4_lot_8_name = name
                      break
                    case 9:
                      this.block_4_lot_9 = 'error'
                      this.block_4_lot_9_name = name
                      break
                    case 10:
                      this.block_4_lot_10 = 'error'
                      this.block_4_lot_10_name = name
                      break
                    case 11:
                      this.block_4_lot_11 = 'error'
                      this.block_4_lot_11_name = name
                      break
                    case 12:
                      this.block_4_lot_12 = 'error'
                      this.block_4_lot_12_name = name
                      break
                    case 13:
                      this.block_4_lot_13 = 'error'
                      this.block_4_lot_13_name = name
                      break
                    case 14:
                      this.block_4_lot_14 = 'error'
                      this.block_4_lot_14_name = name
                      break
                    case 15:
                      this.block_4_lot_15 = 'error'
                      this.block_4_lot_15_name = name
                      break
                  }
                  break
                case 5:
                  switch (item.lot_no) {
                    case 1:
                      this.block_5_lot_1 = 'error'
                      this.block_5_lot_1_name = name
                      break
                    case 2:
                      this.block_5_lot_2 = 'error'
                      this.block_5_lot_2_name = name
                      break
                    case 3:
                      this.block_5_lot_3 = 'error'
                      this.block_5_lot_3_name = name
                      break
                    case 4:
                      this.block_5_lot_4 = 'error'
                      this.block_5_lot_4_name = name
                      break
                    case 5:
                      this.block_5_lot_5 = 'error'
                      this.block_5_lot_5_name = name
                      break
                    case 6:
                      this.block_5_lot_6 = 'error'
                      this.block_5_lot_6_name = name
                      break
                    case 7:
                      this.block_5_lot_7 = 'error'
                      this.block_5_lot_7_name = name
                      break
                    case 8:
                      this.block_5_lot_8 = 'error'
                      this.block_5_lot_8_name = name
                      break
                    case 9:
                      this.block_5_lot_9 = 'error'
                      this.block_5_lot_9_name = name
                      break
                    case 10:
                      this.block_5_lot_10 = 'error'
                      this.block_5_lot_10_name = name
                      break
                    case 11:
                      this.block_5_lot_11 = 'error'
                      this.block_5_lot_11_name = name
                      break
                    case 12:
                      this.block_5_lot_12 = 'error'
                      this.block_5_lot_12_name = name
                      break
                    case 13:
                      this.block_5_lot_13 = 'error'
                      this.block_5_lot_13_name = name
                      break
                    case 14:
                      this.block_5_lot_14 = 'error'
                      this.block_5_lot_14_name = name
                      break
                    case 15:
                      this.block_5_lot_15 = 'error'
                      this.block_5_lot_15_name = name
                      break
                    case 16:
                      this.block_5_lot_16 = 'error'
                      this.block_5_lot_16_name = name
                      break
                    case 17:
                      this.block_5_lot_17 = 'error'
                      this.block_5_lot_17_name = name
                      break
                    case 18:
                      this.block_5_lot_18 = 'error'
                      this.block_5_lot_18_name = name
                      break
                  }
                  break
                case 6:
                  switch (item.lot_no) {
                    case 1:
                      this.block_6_lot_1 = 'error'
                      this.block_6_lot_1_name = name
                      break
                    case 2:
                      this.block_6_lot_2 = 'error'
                      this.block_6_lot_2_name = name
                      break
                    case 3:
                      this.block_6_lot_3 = 'error'
                      this.block_6_lot_3_name = name
                      break
                    case 4:
                      this.block_6_lot_4 = 'error'
                      this.block_6_lot_4_name = name
                      break
                    case 5:
                      this.block_6_lot_5 = 'error'
                      this.block_6_lot_5_name = name
                      break
                    case 6:
                      this.block_6_lot_6 = 'error'
                      this.block_6_lot_6_name = name
                      break
                    case 7:
                      this.block_6_lot_7 = 'error'
                      this.block_6_lot_7_name = name
                      break
                  }
                  break
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
  }
</script>
